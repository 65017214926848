// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

button {
  margin-top: 10px;
  margin-left: auto;
}
`, "",{"version":3,"sources":["webpack://./src/app/not-found/not-found.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":["div {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: center;\n}\n\nbutton {\n  margin-top: 10px;\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
