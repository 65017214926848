import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DialogueData, DialogueType } from '../shared/dialogue';

@Component({
  selector: 'app-dialogue',
  templateUrl: './dialogue.component.html',
  styleUrls: ['./dialogue.component.css']
})
export class DialogueComponent {
  defaultType: DialogueType = DialogueType.DEFAULT;

  constructor(
    public dialogueRef: MatDialogRef < DialogueComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: DialogueData) {}

  /**
   * No click event
   */
  onNoClick() {
    this.dialogueRef.close();
  }

  /**
   * Yes click event
   */
  onYesClick() {
    this.dialogueRef.close();
  }
}
