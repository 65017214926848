// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center-div {
  margin: 0;
  height: 90vh;
  width: 100vw;
}

.input {
  cursor: pointer;
  font-size: 28px;
  font-weight: 600;
  background-color: #78866B;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 10px;
  padding: 10px;
  opacity: 1;
}

.input:hover {
  text-decoration: underline;
}

.input:focus {
  outline-color: #4c5940;
  outline-width: thick;
}

.toolbar {
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: #78866B;
  justify-content: left;
  margin: 0;
  min-width: 800px;
}

.content {
  margin-top: 40px;
}

.cdk-virtual-scroll-viewport {
  text-align: left;
  display: flex;
}

.item {
  height: 19px;
  border: none;
  width: 100%;
  font: inherit;
}

.item:focus {
  outline-color: #4c5940;
}

.context-menu-div {
  position: absolute;
  flex-direction: column;
  background-color: white;
  padding: 5px;
}

.context-menu-button {
  margin: 2px;
}

h1 {
  padding-right: 20px;
  padding-left: 10px;
}

.tree-invisible {
  display: none;
}

.tree ul,
.tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/main/main.component.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,eAAe;EACf,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;AACtB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,MAAM;EACN,YAAY;EACZ,yBAAyB;EACzB,qBAAqB;EACrB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,aAAa;AACf;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,aAAa;EACb,gBAAgB;EAChB,qBAAqB;AACvB","sourcesContent":[".center-div {\n  margin: 0;\n  height: 90vh;\n  width: 100vw;\n}\n\n.input {\n  cursor: pointer;\n  font-size: 28px;\n  font-weight: 600;\n  background-color: #78866B;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  margin-left: 5px;\n  margin-right: 10px;\n  padding: 10px;\n  opacity: 1;\n}\n\n.input:hover {\n  text-decoration: underline;\n}\n\n.input:focus {\n  outline-color: #4c5940;\n  outline-width: thick;\n}\n\n.toolbar {\n  z-index: 1;\n  position: fixed;\n  top: 0;\n  width: 100vw;\n  background-color: #78866B;\n  justify-content: left;\n  margin: 0;\n  min-width: 800px;\n}\n\n.content {\n  margin-top: 40px;\n}\n\n.cdk-virtual-scroll-viewport {\n  text-align: left;\n  display: flex;\n}\n\n.item {\n  height: 19px;\n  border: none;\n  width: 100%;\n  font: inherit;\n}\n\n.item:focus {\n  outline-color: #4c5940;\n}\n\n.context-menu-div {\n  position: absolute;\n  flex-direction: column;\n  background-color: white;\n  padding: 5px;\n}\n\n.context-menu-button {\n  margin: 2px;\n}\n\nh1 {\n  padding-right: 20px;\n  padding-left: 10px;\n}\n\n.tree-invisible {\n  display: none;\n}\n\n.tree ul,\n.tree li {\n  margin-top: 0;\n  margin-bottom: 0;\n  list-style-type: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
